<template lang="pug">
#verify-box-success.step-component
  .vbs-content-wrap
    icon(data="~@icon/person-waving.svg")
    h1 Your Kit checks out!
    p.cs-hours Our Customer Support Agents are available for live calls during these hours:
    p.cs-hours {{ toLocalHour(10) }}:00am - {{ toLocalHour(1) }}:00pm {{ localTimeZone }} Monday-Friday <br> {{ toLocalHour(12) }}:00am - {{ toLocalHour(22) }}:00pm {{ localTimeZone }} Saturday-Sunday
    p.cs-hours If you think that you may need assistance to complete your Kit, please wait to start your kit during our support hours so that our live agents can assist you.

  button.button(@click="clickHandler()") Continue
</template>

<script>
import Holidays from 'date-holidays'
import AmplitudeAPI from '@/utils/amplitude'

export default {
  props: {},

  data() {
    return {
      localTimeZone: null,
    }
  },

  computed: {},

  watch: {},

  methods: {
    clickHandler,
    gotoNext,
    checkBusinessHours,
    nextStep,
    toLocalHour,
  },

  components: {},
  mounted() {
    var date = new Date()
    this.localTimeZone = date.toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]
  },
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function toLocalHour(hour) {
  var utcTime = new Date(`1/1/2099 ${hour}:00:00 UTC`)
  return (utcTime.getHours() + 24) % 12 || 12
}
function clickHandler() {
  if (localStorage.getItem('displayWarning') == 'true') {
    this.checkBusinessHours()
  } else {
    this.gotoNext()
  }
}
function checkBusinessHours() {
  // Function to check if the current day is a holiday
  function checkHolidays(date) {
    return (
      !(holidayCheck && holidays.some(holiday => holiday.name == holidayCheck[0].name)) &&
      date.getDay() != 0
    )
  }
  function checkIfFriSat(date) {
    return [5, 6].includes(date.getDay())
  }
  function checkIfIndependenceDay(date) {
    return date.getMonth() === 6 && [3, 4].includes(date.getDate())
  }
  // Function to check if the day is Friday/Saturday after 12PM
  function checkFriSatAfterNoon(date) {
    return checkIfFriSat(date) && date.getHours() >= 13
  }
  // Function to check if the day is Friday/Saturday before 12PM
  function checkFriSatBeforeNoon(date) {
    return checkIfFriSat(date) && date.getHours() < 13
  }
  const hd = new Holidays('US')
  var holidays = hd.getHolidays(new Date())
  const todaysDate = new Date()
  var holidayCheck = hd.isHoliday(todaysDate)
  var upsDay

  // Filtering out non observed ups holidaus
  holidays = holidays.filter(holiday => {
    return (
      holiday.type == 'public' &&
      ![
        'Martin Luther King Jr. Day',
        "Washington's Birthday",
        'Columbus Day',
        'Veterans Day',
      ].includes(holiday.name)
    )
  })
  // Find the next available UPS day that is not a holiday
  if (checkHolidays(todaysDate) && !checkIfFriSat(todaysDate)) {
    upsDay = new Date(todaysDate)
    upsDay.setHours(6, 0, 0, 0)
  } else {
    var nextDate = new Date(todaysDate)
    nextDate.setDate(nextDate.getDate() + 1)
    holidayCheck = hd.isHoliday(nextDate)
    nextDate.setHours(0)
    while (true) {
      if (checkHolidays(nextDate)) {
        upsDay = new Date(nextDate.setHours(8, 0, 0, 0))
        break
      }
      nextDate.setDate(nextDate.getDate() + 1)
      holidayCheck = hd.isHoliday(nextDate)
    }
  }
  var numberOfHours = (upsDay - todaysDate) / 36e5

  // If Friday, set UPS day to Saturday
  if (todaysDate.getDay() == 5 && checkFriSatBeforeNoon(todaysDate)) {
    upsDay.setDate(upsDay.getDate() + 1)
  }
  //If July 3, set UPS day to july 5
  if (todaysDate.getMonth() == 6 && todaysDate.getDate() == 3) {
    upsDay.setDate(upsDay.getDate() + 2)
  }
  if (numberOfHours > 24 || checkIfFriSat(todaysDate) || checkIfIndependenceDay(todaysDate)) {
    this.$modal.open('HolidayWarning', {
      allowToProceed: true,
      numberOfHours: numberOfHours,
      isSaturday: todaysDate.getDay() == 6,
      isFriday: todaysDate.getDay() == 5,
      isHoliday:
        (numberOfHours > 24 ||
          checkFriSatAfterNoon(todaysDate) ||
          checkIfIndependenceDay(todaysDate)) &&
        !checkFriSatBeforeNoon(todaysDate),
      upsDay: upsDay.toLocaleDateString('en-us', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
      }),
      gotoNext: this.gotoNext,
    })
    AmplitudeAPI.logEvent(
      checkFriSatBeforeNoon(todaysDate) ? 'FriSatMorningAccess' : 'HolidayAccess',
      {
        source: this.$route.query.src,
      }
    )
  } else {
    this.gotoNext()
  }
}
function gotoNext() {
  const warning = JSON.parse(localStorage.getItem('warning'))
  if (Object.keys(warning).length !== 0) {
    this.$modal.open('Warning', {
      warningTitle: warning.title,
      warningText: warning.text,
      buttonType: 'critical',
      callBack: this.nextStep,
      ackBtnText: warning.button,
    })
  } else {
    this.nextStep()
  }
}
function nextStep() {
  this.$emit('complete')
}
</script>
